import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
// import { renderRoutes } from 'react-router-config';
import PrivateRoute from './components/layouts/PrivateRoute';
import AuthenticationProvider from './session/AuthenticationProvider';
import { BlobProvider } from './providers/BlobProvider';

const loading = () => <div className="animated fadeIn pt-3 text-center">Cargando...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./users/Login'));
const Register = React.lazy(() => import('./users/Register'));
const ForgotPass = React.lazy(() => import('./commons/forgotPass/ForgotPass'));
const ResetPass = React.lazy(() => import('./commons/forgotPass/ResetPass'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <AuthenticationProvider>
            <BlobProvider>
              <React.Suspense fallback={loading()}>
                <Switch>
                  <Route exact path="/login" name="Login" render={(props) => <Login {...props} />} />
                  <Route exact path="/register" name="Registro" render={(props) => <Register {...props} />} />
                  <Route exact path="/forgotpass" name="Reestablecer Contraseña" render={(props) => <ForgotPass {...props} />} />
                  <Route exact path="/reset-password" name="Reestablecer Contraseña" render={(props) => <ResetPass {...props} />} />
                  <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                  <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
                  <PrivateRoute path="/" name="Dashboard" render={(props) => <DefaultLayout {...props} />} />
                </Switch>
              </React.Suspense>
            </BlobProvider>
          </AuthenticationProvider>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
